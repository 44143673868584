import getData from "@/__main__/get-data.mjs";
import fetchWikiData from "@/game-eft/fetches/fetch-wiki-data.mjs";
import fleaMarketModel from "@/game-eft/models/flea-market.mjs";
import type { WikiItem } from "@/game-eft/models/model-wiki.mjs";
import * as API from "@/game-eft/utils/api.mjs";
import { getWikiData } from "@/game-eft/utils/use-wiki-data.jsx";
import { NotFoundError } from "@/util/custom-errors.mjs";

async function fetchData(params) {
  const [itemSlug] = params;
  const requiredPromises = [];

  const fleaFetch = getData(
    API.getFleaMarketData(),
    fleaMarketModel,
    ["eft", "fleaMarket"],
    {
      shouldFetchIfPathExists: true,
      mergeFn(current, next) {
        return next ? next : current;
      },
    },
  );
  requiredPromises.push(fleaFetch);

  await fetchWikiData();

  const wiki = getWikiData();

  const item = Object.values<WikiItem>(wiki?.items).find(
    (v) => v.slug === itemSlug,
  );

  if (!item) throw new NotFoundError(`Item ${itemSlug} not found`);

  return Promise.all(requiredPromises);
}

export default fetchData;
